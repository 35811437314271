import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

export default function ContactForm() {
  const [submitStatus, setSubmitStatus] = React.useState({
    message: "",
    status: "",
  });

  const schema = yup.object().shape({
    name: yup.string().required("Name field is required"),
    email: yup
      .string()
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        {
          message: "Email address is not valid",
          excludeEmptyString: true,
        }
      )
      .required("Email field is required"),
    subject: yup.string().required("Subject field is required"),
    message: yup
      .string()
      .max(1000, "Your message exceeds the 1000 character limit")
      .required("Message cannot be empty"),
  });

  const form = useForm({
    resolver: yupResolver(schema),
  });
  const { register, handleSubmit, formState } = form;
  const { errors } = formState;

  async function onSubmit(data) {
    try {
      //http://localhost:3001/send-email for development
      const response = await fetch("https://beyondgames.fr/send-email.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        // Resetting form fields after a successful submission
        form.reset();
        setSubmitStatus({
          status: "success",
          message: "Your message has been sent successfully! 👍",
        });
      } else {
        throw new Error("An error occurred during the form submission.");
      }
    } catch (error) {
      setSubmitStatus({
        status: "error",
        message: error.message || "An error occurred.",
      });
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="name-mail-row">
        <input type="text" placeholder="Name" {...register("name")} />
        <input type="email" placeholder="Email" {...register("email")} />
      </div>
      {errors.name == null ? (
        <p style={{ display: "none" }}></p>
      ) : (
        <p
          style={{
            margin: "0",
            fontStyle: "italic",
            color: "var(--primary-neutral-color)",
          }}
        >
          {errors.name?.message}
        </p>
      )}
      {errors.email == null ? (
        <p style={{ display: "none", fontStyle: "italic" }}></p>
      ) : (
        <p
          style={{
            margin: "0",
            marginBottom: "1rem",
            fontStyle: "italic",
            color: "var(--primary-neutral-color)",
          }}
        >
          {errors.email?.message}
        </p>
      )}

      <div className="subject-row">
        <input type="text" placeholder="Subject" {...register("subject")} />
        <p
          style={{
            fontStyle: "italic",
            color: "var(--primary-neutral-color)",
          }}
        >
          {errors.subject?.message}
        </p>
      </div>
      <div className="message-row">
        <textarea
          placeholder="Message (max 1000 characters)"
          {...register("message")}
        ></textarea>
        <p
          style={{
            fontStyle: "italic",
            color: "var(--primary-neutral-color)",
          }}
        >
          {errors.message?.message}
        </p>
      </div>
      <div className="submit-btn-container">
        <input type="submit" value="Send" />
      </div>
      <p style={{ color: "var(--primary-neutral-color)" }}>
        {submitStatus.message}
      </p>
    </form>
  );
}
