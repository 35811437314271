import { FaqQuestion } from "../../../components/FaqQuestion";
import imgPlusFAQ from "../../../assets/img-faq-plus.png";

export default function FAQ() {
  return (
    <section className="FAQ-wrapper">
      <div className="FAQ-container">
        <div className="FAQ-cta">
          <h2>FAQ</h2>
          <p>Une autre question ?</p>
          <a className="btn blue-btn" href="#footer">
            Contactez-nous
          </a>
        </div>
        <div className="FAQ-panel">
          <FaqQuestion
            symbole={imgPlusFAQ}
            question="Dois-je préparer un cachier des charges ?"
            answer="Pas forcément. Si vous n'en avez pas, nous proposons un accompagnement pour le créer ensemble."
          />
          <FaqQuestion
            symbole={imgPlusFAQ}
            question="Qu'en est-il des données de recherche ?"
            answer="Les données récoltées seront accessibles à tout moment via une interface sécurisée adaptable à vos habitudes de travail."
          />
          <FaqQuestion
            symbole={imgPlusFAQ}
            question="Comment savoir quel budget allouer à mon projet ?"
            answer="Le budget dépend de la compléxité du projet, du volume de fonctionnalités ainsi que du planning souhaité. Il sera estimé après la validation du cahier des charges."
          />
          <FaqQuestion
            symbole={imgPlusFAQ}
            question="Dois-je m'occuper de la validation auprès d'un comité d'éthique ?"
            answer="Oui, il est impératif pour les chercheurs et chercheuses de solliciter l'approbation d'un comité d'éthique pour leurs projets de recherche. Beyond Games propose une consultation gratuite, spécialement axée sur les aspects éthiques de collecte de données en ligne."
          />
          <FaqQuestion
            symbole={imgPlusFAQ}
            question="Puis-je faire appel à vous durant la préparation d'un budget ERC/ANR ?"
            answer="Absolument. C'est avec beaucoup d'enthousiame que nous vous aiderons à compléter votre budget. Ce soutien est crucial pour intégrer de manière justifiée les coûts prévisionnels dans votre demande de financement ERC/ANR. Vous pourrez nous recontacter à l'issue de l'attribution des bourses de recherche. "
          />
        </div>
      </div>
    </section>
  );
}
