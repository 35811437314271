import { useState } from "react";

export default function OfferCard({
  title,
  offerDescription,
  cost,
  delay,
  options,
  exampleDescription,
  exampleURL,
  exampleURLText,
  withAnnotation,
}) {
  const [displayDetails, setDisplayDetails] = useState(false);
  return (
    <div className="offer-card">
      <div className="offer-header">
        <h3>{title}</h3>
        <p>{offerDescription}</p>
      </div>

      <div className="offer-detail">
        <div>
          <p>
            Délais
            <sup className="annotation-1" style={styles.annotation}>
              (1)
            </sup>
          </p>
          <p>{delay}</p>
        </div>
        <div>
          <p>
            Coûts
            <sup className="annotation-1" style={styles.annotation}>
              (1)
            </sup>
          </p>
          <p>{cost}</p>
        </div>
        <div>
          <p>
            Options
            <sup className="annotation-1" style={styles.annotation}>
              (1)
            </sup>
          </p>
          <p>{options}</p>
        </div>
      </div>
      <button onClick={() => setDisplayDetails(!displayDetails)}>
        {displayDetails ? "Masquer l'exemple" : "Voir un exemple de projet"}
      </button>
      <div
        className={`offer-hide ${displayDetails ? "offer-hide-active" : ""}`}
      >
        {displayDetails && (
          <>
            <div className="offer-example">
              <p>Exemple</p>
              <a href={exampleURL} target="_blank" rel="noreferrer">
                {exampleURLText}
              </a>
              {withAnnotation && (
                <sup className="annotation-2" style={styles.annotation2}>
                  (2)
                </sup>
              )}
              <p>{exampleDescription}</p>
            </div>
            <small style={styles.annotationText}>
              (1) Ces estimations dépendent grandement du projet spécifique et
              sont à titre indicatif. Une discussion approfondie sur les besoins
              et les spécificités du projet permettra de préciser le budget et
              le calendrier.
            </small>
            <br />
            {withAnnotation && (
              <small style={styles.annotationText}>
                (2) Cette exemple, bien que non développé par nos équipes, a
                servi d'inspiration pour notre approche et méthodologie.
              </small>
            )}
          </>
        )}
      </div>
    </div>
  );
}

const styles = {
  annotation: {
    fontSize: "0.6em",
  },
  annotation2: {
    fontSize: "0.6em",
    color: "hsla(36, 100%, 49%, 1)",
  },
  annotationText: {
    fontSize: "0.8rem",
    color: "#9d9d9d",
  },
};
