import "../../../../index.css";
import logoNatureWhite from "../../../../assets/nature_journal_logo_white 1.svg";
import threeBranchesOrangeBlop from "../../../../assets/orange-blop-with-3-branches.svg";
import imgRectangleDecoratif from "../../../../assets/img-rectangle-decoratif.svg";
import openMark from "../../../../assets/“.svg";
import closeMark from "../../../../assets/”.svg";
export default function Hero() {
  return (
    <section className="hero-section-wrapper">
      <div className="hero-container">
        <div className="hero-title">
          <h1>
            Creating captivating digital experiences <br />
            <span>
              Dedicated to <strong>behavioral sciences</strong>{" "}
            </span>
          </h1>
        </div>
        <div className="hero-2">
          <div className="hero-list-container">
            <ul>
              <li>
                Gamified experiences
                <img src={imgRectangleDecoratif} alt="" />
              </li>

              <li>
                Websites
                <img src={imgRectangleDecoratif} alt="" />
              </li>

              <li>
                Mobile Apps
                <img src={imgRectangleDecoratif} alt="" />
              </li>
            </ul>
          </div>
          <div className="hero-quote-container">
            <blockquote>
              <p>
                <img className="quotation-marks" src={openMark} alt="" />{" "}
                Scientists should try out gamification on their most creative,
                pressing, and exciting research questions.
                <img className="quotation-marks" src={closeMark} alt="" />
              </p>
              <cite>How games can make behavioural science better? 2023</cite>
            </blockquote>
            <img src={logoNatureWhite} alt="Nature magazine logo" />
          </div>
        </div>
      </div>

      <img
        className="three-branches-blop"
        src={threeBranchesOrangeBlop}
        alt=""
      />
    </section>
  );
}
