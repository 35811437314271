import { GamificationCard } from "../../../../components/EN/GamificationCard";
import One from "../../../../assets/1.svg";
import Two from "../../../../assets/2.svg";
import Three from "../../../../assets/3.svg";
import Four from "../../../../assets/4.svg";
import orangeBlop from "../../../../assets/orange-blop-rounded.svg";

export default function GamificationPros() {
  return (
    <section className="pros-container">
      <div>
        <h2>Benefits of a gamified experience</h2>
      </div>
      <div className="pros-card-container">
        <GamificationCard
          cardVersion="card-v1"
          cardNumImg={One}
          title="Maintains participant engagement"
          text="Gamification revitalizes participant engagement. This sustained interest over the long term also improves the quality of longitudinal studies."
        />
        <GamificationCard
          cardVersion="card-v2"
          cardNumImg={Two}
          title="Ensures sample diversity"
          text="Thanks to digital outreach, gamification allows access to a large number of participants leading to a more diverse panel."
        />
        <GamificationCard
          cardVersion="card-v1"
          cardNumImg={Three}
          title="Improves ecological validity"
          text="Gamification makes the study setting less distant from the participants' daily lives."
        />
        <GamificationCard
          cardVersion="card-v2"
          cardNumImg={Four}
          title="Enhances public accessibility"
          text="Gamification makes science and its understanding accessible to a wider audience."
        />
      </div>
      <img className="blop-left" src={orangeBlop} alt="" />
    </section>
  );
}
