import ContactForm from "../../../../components/EN/ContactForm";

export default function Footer() {
  return (
    <footer id="footer">
      <div className="footer-container">
        <div className="title-container">
          <h2>Contact us</h2>
          <p>For discussions or meetings, it's right here:</p>
        </div>

        <ContactForm />

        <section className="adresse-wrapper">
          <address>
            <p>BEYOND GAMES SAS</p>
            <p>Paris 20th, FRANCE</p>
            <a href="mailto:contact@beyondgames.fr">contact@beyondgames.fr</a>
          </address>
          &copy; 2024 Beyond Games SAS. All rights reserved.
        </section>
      </div>
    </footer>
  );
}
