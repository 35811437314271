import { useState } from "react";
export const FaqQuestion = ({ symbole, question, answer }) => {
  const [displayFAQ, setdisplayFAQ] = useState(false);
  function FAQChange() {
    setdisplayFAQ(!displayFAQ);
  }
  return (
    <div className="FAQ-item">
      <div
        className="FAQ-question"
        onClick={FAQChange}
        style={{ cursor: "pointer" }}
      >
        <h3>{question}</h3>
        <p className="plus-minus"> {displayFAQ ? "-" : "+"}</p>
      </div>
      <div
        className="FAQ-answer"
        style={{
          maxHeight: displayFAQ ? "500px" : "0",
          overflow: "hidden",
          transition: "all 0.8s ease-in-out",
        }}
      >
        <p>{answer}</p>
      </div>
    </div>
  );
};
