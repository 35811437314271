import { ServicesCard } from "../../../../components/EN/ServicesCard";
import imageConseils from "../../../../assets/img-conseils.png";
import imageDesign from "../../../../assets/img-design.png";
import imageGestionProjet from "../../../../assets/img-gestion-de-projet-1.png";
import imageDev from "../../../../assets/img-developpement-applications.png";
import imageCom from "../../../../assets/img-communication.png";
import imageSuivi from "../../../../assets/img-suivi-amelioration.png";

export const OurServices = () => {
  return (
    <section className="our-services-wrapper">
      <div className="our-services-container">
        <h2>Our Services</h2>
        <div className="services-card-panel">
          <div className="service-row">
            <ServicesCard
              image={imageConseils}
              title="Consulting"
              text="Imagine, conceptualize, and draft the outlines of your project."
            />
            <ServicesCard
              image={imageDesign}
              title="Design"
              text="Gamify and create the best interface for the user."
            />
          </div>
          <div className="service-row">
            <ServicesCard
              image={imageGestionProjet}
              title="Project Management"
              text="Centralize information, coordinate and answer your questions."
            />
            <ServicesCard
              image={imageDev}
              title="Development"
              text="Build the project with the right technologies for web or mobile."
            />
          </div>
          <div className="service-row">
            <ServicesCard
              image={imageCom}
              title="Communication"
              text="Deploy communication strategies to disseminate and engage the public in your research work."
            />
            <ServicesCard
              image={imageSuivi}
              title="Monitoring and Improvement"
              text="Support after launch to ensure the project runs smoothly and evolve it as needed."
            />
          </div>
        </div>
      </div>
    </section>
  );
};
