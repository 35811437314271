import illuOne from "../../../assets/illu-experience-gamifiied.svg";
import illuTwo from "../../../assets/illu-presentation-projet.svg";
import illuThree from "../../../assets/illu-site-app-mobile.svg";
import natureLogo from "../../../assets/nature_journal_logo 1.svg";
import openMark from "../../../assets/“darkblue.svg";
import closeMark from "../../../assets/”darkblue.svg";
import orangeBlop from "../../../assets/orange-blop-rounded.svg";
import logoENS from "../../../assets/img_ENS_PSL.jpg";
import logoNicod from "../../../assets/img_institut_nicod.png";
import OfferCard from "../../../components/OfferCard";

//CONTENU POUR LA SECTION OFFRE "CE QU'ON PEUT FAIRE"
const offerList = [
  {
    title: "Site de communication scientifique",
    offerDescription:
      "Conception de sites web pour présenter et promouvoir les activités de recherche d'un département, d'une équipe, d'un projet spécifique, ou à visé de médiation scientifique. Peut inclure la visualisation dynamique de données pour une communication efficace des résultats.",
    cost: "1 000€ - 3 000€",
    delay: "7 jours - 2 mois",
    options:
      "Ajout de données en temps réel - Publications d'articles de blog - Ajouts de nouveaux membres du groupe",
    exampleDescription:
      "Le site Forgotten Book est une plateforme de communication dédiée à la présentation d'un article publié par la revue Science, qui se concentre sur l'évaluation du taux de survie des artefacts culturels.",
    exampleURL: "https://forgotten-books.netlify.app",
    exampleURLText: "Forgotten Books",
    withAnnotation: true,
  },

  {
    title: "Questionnaire en ligne",
    offerDescription:
      "Développement de questionnaires personnalisés pour des études psychologiques ou sociologiques. Offre une flexibilité supérieure aux outils standard.",
    cost: "7 000€ - 15 000€",
    delay: "4 - 6 mois",
    options:
      "Paramétrage des items du questionnaire - Portail d'accès aux données en temps réel",
    exampleDescription:
      "Le site DEEP (en cours de construction par nos équipes) est conçu pour faciliter la collecte des résultats des questionnaires spécifiquement adaptés aux joueurs de jeux vidéo.",
    exampleURL: "https://www.the-deepmodel.fr/",
    exampleURLText: "DEEP",
    withAnnotation: false,
  },
  {
    title: "Tâche comportementale en ligne",
    offerDescription:
      "Adaptation ou création de tâches pour mesurer des construits psychologiques spécifiques, augmentant la portée des études comportementales par rapport aux méthodologies traditionnelles. Offre la possibilité de créer des expérimentations contrôlées en répartissant aléatoirement les participants dans plusieurs conditions.",
    cost: "15 000€ - 30 000€",
    delay: "6 - 12 mois",
    options:
      "Paramétrage des réglages de la tâche - Portail d'accès aux données en temps réel",
    exampleDescription:
      "Le site Music Lab est une plateforme interactive qui propose une série de mini-tâches autour de la musique, lesquels ont été joués jusqu'à des centaines de milliers de fois, et qui ont lieu à des articles publiés dans PNAS, Nature Human Behavior ou encore Current Biology.",
    exampleURL: "https://www.themusiclab.org",
    exampleURLText: "The Music Lab",
    withAnnotation: true,
  },
  {
    title: "Étude longitudinale en ligne",
    offerDescription:
      "Mise en place de systèmes de collecte de données sur le long terme, permettant d'analyser les changements comportementaux et leurs causes. Les fonctionnalités ludiques et les notifications réduisent le taux d'abandon.",
    cost: "10 000€ - 50 000€",
    delay: "6 - 12 mois",
    options:
      "Accès par comptes utilisateurs identifiés et confirmés via un mot de passe confidentiel - Notifications à un jour et une heure donnés - Ajout de questions ou de tâches - Portail d'accès aux données en temps réel",
    exampleDescription:
      "The Color Game est une application développée par le Max Planck Institute, qui invite les joueurs à communiquer uniquement des couleurs pour étudier l'évolution du langage à travers les modifications de l'utilisation de ces symboles au fil du temps.",
    exampleURL: "https://www.shh.mpg.de/928779/color-game",
    exampleURLText: "Colour Game App",
    withAnnotation: true,
  },
  {
    title: "Expérimentation gamifiée",
    offerDescription:
      "Développement de jeux vidéo destinés à la recherche, permettant d'étudier des comportements dans un contexte ludique et contrôlé. Idéal pour mesurer des dynamiques complexes dans des situations réalistes.",
    cost: "150 000€ - 300 000€",
    delay: "1 - 3 ans",
    options:
      "Mécanique de jeux vidéo - Graphisme élaboré - Portail d'accès aux données en temps réel",
    exampleDescription:
      "Sea Hero Quest est un jeu sur smartphone conçu pour collecter des données sur l'orientation spatiale afin d'identifier des signes précoces de déclin cognitif, prédictifs de la maladie d'Alzheimer chez les personnes sans symptômes, avec des résultats publiés dans PLOS ONE et Topics in Cognitive Science.",
    exampleURL:
      "https://www.alzheimersresearchuk.org/research/for-researchers/resources-and-information/sea-hero-quest/",
    exampleURLText: "Sea Hero Quest App",
    withAnnotation: true,
  },
];

export default function OurMission() {
  return (
    <div className="our-mission-wrapper">
      <section className="project-container">
        <div className="flex-col-center-wrapper">
          <h2>L'agence web de vos projets de recherches</h2>
          <div className="flex-row">
            <div className="flex-col-center-element">
              <div
                style={{
                  borderBlockColor: "purple",
                  height: "60%",
                }}
              >
                <img src={illuOne} alt="" />
              </div>
              <div style={{ minHeight: "20%" }}>
                <p>Expériences gamifiées.</p>
              </div>
            </div>
            <div className="flex-col-center-element">
              <div
                style={{
                  height: "60%",
                }}
              >
                <img src={illuTwo} alt="" />
              </div>
              <div style={{ minHeight: "20%" }}>
                <p>Présentation de vos recherches.</p>
              </div>
            </div>
            <div className="flex-col-center-element">
              <div
                style={{
                  height: "60%",
                }}
              >
                <img src={illuThree} alt="" />
              </div>
              <div style={{ minHeight: "20%" }}>
                <p>Sites web et applications mobiles</p>
              </div>
            </div>
          </div>
        </div>
        <a className="btn blue-btn" href="#footer">
          Prendre rendez-vous
        </a>
        <section className="partenaire-section">
          <h2 style={{}}>Ils nous ont fait confiance</h2>
          <div className="partenaire-logo">
            <img src={logoENS} alt="logo de l'école national supérieur" />

            <img
              src={logoNicod}
              alt="logo du centre de recherche institut Jean Nicod"
            />
          </div>
        </section>
        {/*Début de la section CE QUE NOUS POUVONS FAIRE*/}
        <section className="offer-section">
          <h2>Nos Solutions</h2>
          <div className="offer-wrapper">
            {offerList.map((offer, index) => (
              <OfferCard
                key={index}
                title={offer.title}
                offerDescription={offer.offerDescription}
                delay={offer.delay}
                cost={offer.cost}
                options={offer.options}
                exampleDescription={offer.exampleDescription}
                exampleURL={offer.exampleURL}
                exampleURLText={offer.exampleURLText}
                withAnnotation={offer.withAnnotation}
              />
            ))}
          </div>
        </section>
      </section>
      <section className="value-wrapper">
        <div className="engagement-wrapper">
          <div className="engagement-container">
            <h2>L'engagement Beyond Games</h2>
            <p>
              C’est la mise en œuvre de toutes les compétences indispensables au
              processus de <em>gamification</em> et à la <em>digitalisation</em>{" "}
              des sciences comportementales, au travers d’un engagement durable
              dans vos projets de recherche.
            </p>
          </div>
          <div className="nature-quote-container">
            <blockquote>
              <p>
                <img className="quotation-marks" src={openMark} alt="" />
                Les scientifiques doivent se tourner vers des professions
                extérieures à la recherche pour élargir leurs boîtes à outils
                techniques.{" "}
                <img className="quotation-marks" src={closeMark} alt="" />
              </p>
              <cite>
                Comment les jeux peuvent améliorer les sciences comportementales
                ? 2023
              </cite>
            </blockquote>
            <img className="nature-logo" src={natureLogo} alt="" />
          </div>
          {<img className="blop-right" src={orangeBlop} alt="" />}
        </div>
        <div className="accompagnement-wrapper">
          <div className="accompagnement-container">
            <h2>L'accompagnement Beyond Games</h2>
            <ul>
              <li>
                <span className="capital-text bolded-text">écoute, </span>pour
                une bonne collaboration et un cadrage efficace du projet.
              </li>
              <li>
                <span className="capital-text bolded-text">Créativité, </span>
                technique et esthétique pour l’élaboration de la solution.
              </li>
              <li>
                <span className="capital-text bolded-text">Rigueur, </span>pour
                répondre aux exigences de la recherche.
              </li>
              <li>
                <span className="capital-text bolded-text">Adaptabilité, </span>
                parce qu’un projet évolue.
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
}
