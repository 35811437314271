import "../../../index.css";
import logoBG from "../../../assets/beyond-games-logo.svg";
import iconBG from "../../../assets/icon-beyg.svg";

export default function Header({ lang, setLang }) {
  return (
    <header className="header-wrapper">
      <div className="header-container">
        <div style={{ display: "flex" }}>
          <img className="icon" src={iconBG} alt="Beyond Games company icon" />
          <img src={logoBG} alt="Logo de l'entreprise Beyond Games" />

          <div
            style={{
              paddingLeft: "1rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            <button
              className={`lang-btn ${lang === "FR" ? "underlined" : ""}`}
              onClick={() => setLang("FR")}
            >
              FR
            </button>
            <button
              className={`lang-btn ${lang === "EN" ? "underlined" : ""}`}
              onClick={() => setLang("EN")}
            >
              EN
            </button>
          </div>
        </div>
        <a className="btn white-btn" href="#footer">
          Contactez nous
        </a>
      </div>
    </header>
  );
}
