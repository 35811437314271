import { FaqQuestion } from "../../../../components/EN/FaqQuestion";
import imgPlusFAQ from "../../../../assets/img-faq-plus.png";

export default function FAQ() {
  return (
    <section className="FAQ-wrapper">
      <div className="FAQ-container">
        <div className="FAQ-cta">
          <h2>FAQ</h2>
          <p>Have another question?</p>
          <a className="btn blue-btn" href="#footer">
            Contact us
          </a>
        </div>
        <div className="FAQ-panel">
          <FaqQuestion
            symbole={imgPlusFAQ}
            question="Do I need to prepare a specification document?"
            answer="Not necessarily. If you don't have one, we offer support to create it together."
          />
          <FaqQuestion
            symbole={imgPlusFAQ}
            question="What about research data?"
            answer="The collected data will be accessible at any time via a secure interface adaptable to your work habits."
          />
          <FaqQuestion
            symbole={imgPlusFAQ}
            question="How do I know what budget to allocate to my project?"
            answer="The budget depends on the project's complexity, the volume of features, and the desired timeline. It will be estimated after the specification document is validated."
          />
          <FaqQuestion
            symbole={imgPlusFAQ}
            question="Do I need to take care of validation with an ethics committee?"
            answer="Yes, it is imperative for researchers to seek approval from an ethics committee for their research projects. Beyond Games offers a free consultation, specifically focused on the ethical aspects of online data collection."
          />
          <FaqQuestion
            symbole={imgPlusFAQ}
            question="Can I contact you during the preparation of an ERC/ANR budget?"
            answer="Absolutely. We are very enthusiastic to help you complete your budget. This support is crucial to justifiably integrate the forecasted costs into your ERC/ANR funding request. You can contact us again after the research grants are awarded."
          />
        </div>
      </div>
    </section>
  );
}
