import "../../../index.css";
import logoNatureWhite from "../../../assets/nature_journal_logo_white 1.svg";
import threeBranchesOrangeBlop from "../../../assets/orange-blop-with-3-branches.svg";
import imgRectangleDecoratif from "../../../assets/img-rectangle-decoratif.svg";
import openMark from "../../../assets/“.svg";
import closeMark from "../../../assets/”.svg";
export default function Hero() {
  return (
    <section className="hero-section-wrapper">
      <div className="hero-container">
        <div className="hero-title">
          <h1>
            La création d'expériences numériques <br />
            captivantes <br />
            <span>
              au service des <strong>sciences comportementales</strong>
            </span>
          </h1>
        </div>
        <div className="hero-2">
          <div className="hero-list-container">
            <ul>
              <li>
                Expériences gamifiées
                <img src={imgRectangleDecoratif} alt="" />
              </li>

              <li>
                Sites Web
                <img src={imgRectangleDecoratif} alt="" />
              </li>

              <li>
                Applications Mobiles
                <img src={imgRectangleDecoratif} alt="" />
              </li>
            </ul>
          </div>
          <div className="hero-quote-container">
            <blockquote>
              <p>
                <img className="quotation-marks" src={openMark} alt="" /> Les
                scientifiques devraient essayer la ludification sur leurs
                questions de recherche les plus créatives, urgentes et
                excitantes.
                <img className="quotation-marks" src={closeMark} alt="" />
              </p>
              <cite>
                Comment les jeux peuvent améliorer les sciences comportementales
                ? 2023
              </cite>
            </blockquote>
            <img src={logoNatureWhite} alt="Logo magazine Nature" />
          </div>
        </div>
      </div>

      <img
        className="three-branches-blop"
        src={threeBranchesOrangeBlop}
        alt=""
      />
    </section>
  );
}
