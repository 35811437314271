import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

export default function ContactForm() {
  const [submitStatus, setSubmitStatus] = React.useState({
    message: "",
    status: "",
  });

  const schema = yup.object().shape({
    name: yup.string().required("Le champ nom est obligatoire"),
    email: yup
      .string()
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        {
          message: "L'adresse email n'est pas valide",
          excludeEmptyString: true,
        }
      )
      .required("Le champ email est obligatoire"),
    subject: yup.string().required("Le champ sujet est obligatoire"),
    message: yup
      .string()
      .max(1000, "Votre message dépasse les 1000 caractères autorisé")
      .required("Le message ne peut pas être vide"),
  });

  const form = useForm({
    resolver: yupResolver(schema),
  });
  const { register, handleSubmit, formState } = form;
  const { errors } = formState;

  async function onSubmit(data) {
    try {
      //http://localhost:3001/send-email pour le dev
      const response = await fetch("https://beyondgames.fr/send-email.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        // Réinitialisation des champs du formulaire après une soumission réussie
        form.reset();
        setSubmitStatus({
          status: "success",
          message: "Votre message a été envoyé avec succès !👍",
        });
      } else {
        throw new Error(
          "Une erreur est survenue lors de l'envoi du formulaire."
        );
      }
    } catch (error) {
      setSubmitStatus({
        status: "error",
        message: error.message || "Une erreur est survenue.",
      });
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="name-mail-row">
        <input type="text" placeholder="Nom" {...register("name")} />
        <input type="email" placeholder="Email" {...register("email")} />
      </div>
      {errors.name == null ? (
        <p style={{ display: "none" }}></p>
      ) : (
        <p
          style={{
            margin: "0",
            fontStyle: "italic",
            color: "var(--primary-neutral-color)",
          }}
        >
          {errors.name?.message}
        </p>
      )}
      {errors.email == null ? (
        <p style={{ display: "none", fontStyle: "italic" }}></p>
      ) : (
        <p
          style={{
            margin: "0",
            marginBottom: "1rem",
            fontStyle: "italic",
            color: "var(--primary-neutral-color)",
          }}
        >
          {errors.email?.message}
        </p>
      )}

      <div className="subject-row">
        <input type="text" placeholder="Sujet" {...register("subject")} />
        <p
          style={{
            fontStyle: "italic",
            color: "var(--primary-neutral-color)",
          }}
        >
          {errors.subject?.message}
        </p>
      </div>
      <div className="message-row">
        <textarea
          placeholder="Message (1000 caractères max.)"
          {...register("message")}
        ></textarea>
        <p
          style={{
            fontStyle: "italic",
            color: "var(--primary-neutral-color)",
          }}
        >
          {errors.message?.message}
        </p>
      </div>
      <div className="submit-btn-container">
        <input type="submit" value="Envoyer" />
      </div>
      <p style={{ color: "var(--primary-neutral-color)" }}>
        {submitStatus.message}
      </p>
    </form>
  );
}

/*STYLES*/

/*async function onSubmit(data) {
  try {
    const response = await fetch('URL_DE_VOTRE_API', { // Remplacez URL_DE_VOTRE_API par l'URL de votre endpoint backend
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      // Si la réponse n'est pas ok, on lance une erreur avec le statut pour la catch plus tard
      throw new Error(`Erreur HTTP: ${response.status}`);
    }
    const responseData = await response.json();
    console.log('Success:', responseData);
    // Affichez ici le message de succès à l'utilisateur, par exemple en modifiant l'état du composant
    alert('Message envoyé avec succès !'); // Exemple simple avec alert, envisagez d'utiliser un état pour un message dans l'UI
  } catch (error) {
    console.error('Error:', error);
    // Affichez ici le message d'erreur à l'utilisateur, par exemple en modifiant l'état du composant
    alert('Erreur lors de l\'envoi du message. Veuillez réessayer.'); // Exemple simple avec alert, envisagez d'utiliser un état pour un message dans l'UI
  }
}*/

/* 
const [submitStatus, setSubmitStatus] = React.useState({
    message: '',
    status: ''
  });

async function onSubmit(data) {
    try {
      const response = await fetch('https://formsubmit.co/your@email.com', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json', // FormSubmit demande d'inclure cet en-tête pour accepter le JSON
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        setSubmitStatus({
          status: 'success',
          message: 'Votre message a été envoyé avec succès.'
        });
      } else {
        throw new Error('Une erreur est survenue lors de l\'envoi du formulaire.');
      }
    } catch (error) {
      setSubmitStatus({
        status: 'error',
        message: error.message || 'Une erreur est survenue.'
      });
    }
  }
*/
