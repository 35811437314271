import "../../index.css";
import HomeFR from "./HomeFR";
import HomeEN from "./HomeEN";
import { useState } from "react";

function Home() {
  const [lang, setLang] = useState("EN"); // 'FR' pour français, 'EN' pour anglais

  return (
    <div className="wrapper">
      {lang === "FR" ? (
        <HomeFR setLang={setLang} lang={lang} />
      ) : (
        <HomeEN setLang={setLang} lang={lang} />
      )}
    </div>
  );
}

export default Home;
