import { GamificationCard } from "../../../components/GamificationCard";
import One from "../../../assets/1.svg";
import Two from "../../../assets/2.svg";
import Three from "../../../assets/3.svg";
import Four from "../../../assets/4.svg";
import orangeBlop from "../../../assets/orange-blop-rounded.svg";

export default function GamificationPros() {
  return (
    <section className="pros-container">
      <div>
        <h2>Les avantages d'une expérience gamifiée</h2>
      </div>
      <div className="pros-card-container">
        <GamificationCard
          cardVersion="card-v1"
          cardNumImg={One}
          title="Maintient l’engagement des participants"
          text="La gamification revitalise l’engagement des participants. Cet intérêt maintenu sur le long terme améliore aussi la qualité
des études longitudinales."
        />
        <GamificationCard
          cardVersion="card-v2"
          cardNumImg={Two}
          title="Garantit la diversité des échantillons"
          text="Grâce à la portée du digital, la gamification permet
d’atteindre un grand nombre de participants menant à un panel plus diversifié."
        />
        <GamificationCard
          cardVersion="card-v1"
          cardNumImg={Three}
          title="Améliore la validité écologique"
          text="La gamification rends le cadre de l'étude moins éloigné du quotidien des participants."
        />
        <GamificationCard
          cardVersion="card-v2"
          cardNumImg={Four}
          title="Améliore l’accessibilité au public"
          text="La gamification rend
la science et sa compréhension accessible au plus grand nombre."
        />
      </div>
      <img className="blop-left" src={orangeBlop} alt="" />
    </section>
  );
}
