export const GamificationCard = ({ cardVersion, cardNumImg, title, text }) => {
  return (
    <div className={`pros-card ${cardVersion}`}>
      <div className="pros-card-number">
        <img src={cardNumImg} alt="" />
      </div>
      <div className="pros-card-description">
        <h3>{title}</h3>
        <p>{text}</p>
      </div>
    </div>
  );
};
