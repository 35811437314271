import ContactForm from "../../../components/ContactForm";

export default function Footer() {
  return (
    <footer id="footer">
      <div className="footer-container">
        <div className="title-container">
          <h2>Contactez nous</h2>
          <p>Pour échanger ou se rencontrer, c’est par ici :</p>
        </div>

        <ContactForm />

        <section className="adresse-wrapper">
          <address>
            <p>BEYOND GAMES SAS</p>
            <p>Paris 20e, FRANCE</p>
            <a href="mailto:contact@beyondgames.fr">contact@beyondgames.fr</a>
          </address>
          &copy; 2024 Beyond Games SAS. Tous droits réservés.
        </section>
      </div>
    </footer>
  );
}
