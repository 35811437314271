import FAQ from "./sections/faq";
import Footer from "./sections/footer";
import Header from "./sections/header";
import Hero from "./sections/hero";
import OurMission from "./sections/our-mission";
import { OurServices } from "./sections/our-services";
import GamificationPros from "./sections/gamification-pros";
import "../../index.css";

function Home({ lang, setLang }) {
  return (
    <div className="wrapper">
      <Header setLang={setLang} lang={lang}></Header>
      <main>
        <Hero></Hero>
        <OurMission></OurMission>
        <OurServices></OurServices>
        <GamificationPros></GamificationPros>
        <FAQ></FAQ>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default Home;
