import illuOne from "../../../../assets/illu-experience-gamifiied.svg";
import illuTwo from "../../../../assets/illu-presentation-projet.svg";
import illuThree from "../../../../assets/illu-site-app-mobile.svg";
import natureLogo from "../../../../assets/nature_journal_logo 1.svg";
import openMark from "../../../../assets/“darkblue.svg";
import closeMark from "../../../../assets/”darkblue.svg";
import orangeBlop from "../../../../assets/orange-blop-rounded.svg";
import logoENS from "../../../../assets/img_ENS_PSL.jpg";
import logoNicod from "../../../../assets/img_institut_nicod.png";
import OfferCard from "../../../../components/EN/OfferCard";

//CONTENT FOR THE "WHAT WE CAN DO" SECTION OFFER
const offerList = [
  {
    title: "Scientific communication website",
    offerDescription:
      "Design of websites to present and promote the research activities of a department, a team, a specific project or event. Can include dynamic data visualization for effective communication of results.",
    cost: "1,000€ - 3,000€",
    delay: "7 days - 2 months",
    options:
      "Adding real-time data - Blog article publications - Adding new group members",
    exampleDescription:
      "The Forgotten Book site is a communication platform dedicated to presenting an article published by the journal Science, focusing on assessing the survival rate of cultural artifacts.",
    exampleURL: "https://forgotten-books.netlify.app",
    exampleURLText: "Forgotten Books",
    withAnnotation: true,
  },

  {
    title: "Online questionnaire",
    offerDescription:
      "Development of customized questionnaires for psychological or sociological studies. Offers superior flexibility to standard tools.",
    cost: "7,000€ - 15,000€",
    delay: "4 - 6 months",
    options: "Questionnaire item settings - Real-time data access portal",
    exampleDescription:
      "The DEEP site is designed to facilitate the collection of results from questionnaires specifically adapted to video game players.",
    exampleURL: "https://www.the-deepmodel.fr/",
    exampleURLText: "DEEP",
    withAnnotation: false,
  },
  {
    title: "Online behavioral task",
    offerDescription:
      "Adaptation or creation of tasks to measure specific psychological constructs, increasing the reach of behavioral studies compared to traditional methodologies. Offers the possibility of creating controlled experiments by randomly distributing participants across multiple conditions.",
    cost: "15,000€ - 30,000€",
    delay: "6 - 12 months",
    options: "Task settings configuration - Real-time data access portal",
    exampleDescription:
      "The Music Lab site is an interactive platform offering a series of mini-tasks around music, which have been played up to hundreds of thousands of times, leading to articles published in PNAS, Nature Human Behavior, and Current Biology.",
    exampleURL: "https://www.themusiclab.org",
    exampleURLText: "The Music Lab",
    withAnnotation: true,
  },
  {
    title: "Online longitudinal study",
    offerDescription:
      "Implementation of long-term data collection systems, allowing the analysis of behavioral changes and their causes. Gamification features and notifications reduce dropout rates.",
    cost: "10,000€ - 50,000€",
    delay: "6 - 12 months",
    options:
      "Access through identified and confirmed user accounts via a confidential password - Notifications on a specific day and time - Adding questions or tasks - Real-time data access portal",
    exampleDescription:
      "The Color Game is an app developed by the Max Planck Institute, inviting players to communicate using only colors to study the evolution of language through changes in the use of these symbols over time.",
    exampleURL: "https://www.shh.mpg.de/928779/color-game",
    exampleURLText: "Colour Game App",
    withAnnotation: true,
  },
  {
    title: "Gamified experimentation",
    offerDescription:
      "Development of video games for research, allowing the study of behaviors in a fun and controlled context. Ideal for measuring complex dynamics in realistic situations.",
    cost: "150,000€ - 300,000€",
    delay: "1 - 3 years",
    options:
      "Video game mechanics - Elaborate graphics - Real-time data access portal",
    exampleDescription:
      "Sea Hero Quest is a smartphone game designed to collect data on spatial orientation to identify early signs of cognitive decline, predictive of Alzheimer's disease in asymptomatic individuals, with results published in PLOS ONE and Topics in Cognitive Science.",
    exampleURL:
      "https://www.alzheimersresearchuk.org/research/for-researchers/resources-and-information/sea-hero-quest/",
    exampleURLText: "Sea Hero Quest App",
    withAnnotation: true,
  },
];

export default function OurMission() {
  return (
    <div className="our-mission-wrapper">
      <section className="project-container">
        <div className="flex-col-center-wrapper">
          <h2>Your research projects' web agency</h2>
          <div className="flex-row">
            <div className="flex-col-center-element">
              <div
                style={{
                  borderBlockColor: "purple",
                  height: "60%",
                }}
              >
                <img src={illuOne} alt="" />
              </div>
              <div style={{ minHeight: "20%" }}>
                <p>Gamified experiences.</p>
              </div>
            </div>
            <div className="flex-col-center-element">
              <div
                style={{
                  height: "60%",
                }}
              >
                <img src={illuTwo} alt="" />
              </div>
              <div style={{ minHeight: "20%" }}>
                <p>Presentation of your research.</p>
              </div>
            </div>
            <div className="flex-col-center-element">
              <div
                style={{
                  height: "60%",
                }}
              >
                <img src={illuThree} alt="" />
              </div>
              <div style={{ minHeight: "20%" }}>
                <p>Websites and mobile apps</p>
              </div>
            </div>
          </div>
        </div>
        <a className="btn blue-btn" href="#footer">
          Schedule a meeting
        </a>
        <section className="partenaire-section">
          <h2 style={{}}>They trusted us</h2>
          <div className="partenaire-logo">
            <img src={logoENS} alt="École Normale Supérieure logo" />

            <img
              src={logoNicod}
              alt="Jean Nicod Institute research center logo"
            />
          </div>
        </section>
        {/*Start of WHAT WE CAN DO section*/}
        <section className="offer-section">
          <h2>Our Solutions</h2>
          <div className="offer-wrapper">
            {offerList.map((offer, index) => (
              <OfferCard
                key={index}
                title={offer.title}
                offerDescription={offer.offerDescription}
                delay={offer.delay}
                cost={offer.cost}
                options={offer.options}
                exampleDescription={offer.exampleDescription}
                exampleURL={offer.exampleURL}
                exampleURLText={offer.exampleURLText}
                withAnnotation={offer.withAnnotation}
              />
            ))}
          </div>
        </section>
      </section>
      <section className="value-wrapper">
        <div className="engagement-wrapper">
          <div className="engagement-container">
            <h2>Beyond Games commitment</h2>
            <p>
              It is the implementation of all the essential skills for the
              process of <em>gamification</em> and <em>digitalization</em> of
              behavioral sciences, through a lasting commitment in your research
              projects.
            </p>
          </div>
          <div className="nature-quote-container">
            <blockquote>
              <p>
                <img className="quotation-marks" src={openMark} alt="" />
                Scientists need to look to professions outside research to
                broaden their technical toolkits.
                <img className="quotation-marks" src={closeMark} alt="" />
              </p>
              <cite>How games can make behavioural science better? 2023</cite>
            </blockquote>
            <img className="nature-logo" src={natureLogo} alt="" />
          </div>
          {<img className="blop-right" src={orangeBlop} alt="" />}
        </div>
        <div className="accompagnement-wrapper">
          <div className="accompagnement-container">
            <h2>Beyond Games support</h2>
            <ul>
              <li>
                <span className="capital-text bolded-text">Listening, </span>for
                good collaboration and effective project framing.
              </li>
              <li>
                <span className="capital-text bolded-text">Creativity, </span>
                technical and aesthetic for the development of the solution.
              </li>
              <li>
                <span className="capital-text bolded-text">Rigor, </span>to meet
                research standards.
              </li>
              <li>
                <span className="capital-text bolded-text">Adaptability, </span>
                because a project evolves.
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
}
