import { ServicesCard } from "../../../components/ServicesCard";
import imageConseils from "../../../assets/img-conseils.png";
import imageDesign from "../../../assets/img-design.png";
import imageGestionProjet from "../../../assets/img-gestion-de-projet-1.png";
import imageDev from "../../../assets/img-developpement-applications.png";
import imageCom from "../../../assets/img-communication.png";
import imageSuivi from "../../../assets/img-suivi-amelioration.png";

export const OurServices = () => {
  return (
    <section className="our-services-wrapper">
      <div className="our-services-container">
        <h2>Nos Services</h2>
        <div className="services-card-panel">
          <div className="service-row">
            <ServicesCard
              image={imageConseils}
              title="Conseils"
              text="Imaginer, penser et rédiger les contours de votre projet."
            />
            <ServicesCard
              image={imageDesign}
              title="Design"
              text="Gamifier et créer la meilleure interface pour l’utilisateur."
            />
          </div>
          <div className="service-row">
            <ServicesCard
              image={imageGestionProjet}
              title="Gestion de projet"
              text="Centraliser les informations, coordonner et répondre à vos interrogations."
            />
            <ServicesCard
              image={imageDev}
              title="Développement"
              text="Construire le projet avec les technologies adaptées pour le web ou le mobile."
            />
          </div>
          <div className="service-row">
            <ServicesCard
              image={imageCom}
              title="Communication"
              text="Déployer des stratégies de communication pour diffuser et engager le public dans vos travaux de recherches."
            />
            <ServicesCard
              image={imageSuivi}
              title="Suivi et amélioration"
              text="Accompagner après la mise en ligne pour assurer le bon fonctionnement  du projet et le faire évoluer au besoin."
            />
          </div>
        </div>
      </div>
    </section>
  );
};
